<template>

    <Head>
        <title>{{ page.meta_title }}</title>
        <meta name="description" v-if="page.meta_description" :content="page.meta_description">
    </Head>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"/>

    <section class="bg-white dark:bg-black dark:text-white">
        <div class="mx-auto max-w-7xl px-2 sm:px-6">
            <div class="mx-auto max-w-2xl py-5  lg:max-w-none">
                <div class="flex flex-wrap px-1 mb-5">
                    <div class="w-full lg:w-7/12 ">
                        <slot name="page_left">
                        </slot>
                        <div class="m-4 max-w-lg">
                            <jet-form-section @submitted="submit">
                                <template #form>
                                    <div class="col-span-6 sm:col-span-4 pb-6">
                                        <jet-label for="name" value="Uw naam"/>
                                        <jet-input id="name" type="text" class="mt-1 col-1 resize border rounded-md bg-gray-50 py-2.5 px-2.5" v-model="form.name"/>
                                        <jet-input-error :message="form.errors.name"
                                                         class="mt-2"/>
                                    </div>

                                    <div class="col-span-6 sm:col-span-4 pb-6">
                                        <jet-label for="email" value="Uw e-mail"/>
                                        <jet-input id="email" type="text" class="mt-1 col-1 resize border rounded-md bg-gray-50 py-2.5 px-2.5" v-model="form.email"/>
                                        <jet-input-error :message="form.errors.email"
                                                         class="mt-2"/>
                                    </div>

                                    <div class="col-span-6 sm:col-span-4 pb-6">
                                        <jet-label for="phone" value="Uw telefoonnummer"/>
                                        <jet-input id="phone" type="text" class="mt-1 col-1 resize border rounded-md bg-gray-50 py-2.5 px-2.5" v-model="form.phone"/>
                                        <jet-input-error :message="form.errors.phone"
                                                         class="mt-2"/>
                                    </div>

                                    <div class="col-span-6 sm:col-span-4 pb-6">
                                        <jet-label for="message" value="Uw message"/>
                                        <jet-textarea id="message" type="text" class="mt-1 col-1 w-full resize border rounded-md bg-gray-50 py-2.5 px-2.5" v-model="form.message"/>
                                        <jet-input-error :message="form.errors.message"
                                                         class="mt-2"/>
                                    </div>
                                </template>
                                <template #actions>
                                    <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                                        Verstuurd.
                                    </jet-action-message>

                                    <jet-button :class="' bg-green-900 inline-flex rounded text-lg py-2 px-6 text-white '+{ 'opacity-25': form.processing }"
                                                :disabled="form.processing">
                                        Versturen
                                    </jet-button>
                                </template>
                            </jet-form-section>
                        </div>
                    </div>
                    <div class="w-full lg:w-5/12 ">
                        <div class="-mr-4 -ml-4">
                            <slot name="page_right">
                            </slot>
                        </div>

                        <div class="grid grid-cols-2 pb-4">
                            <div class="pt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" class="float-left mr-2 "
                                     width="30"
                                     height="30" viewBox="0 0 24 24" fill="none" stroke="#083d77"
                                     stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                    <circle cx="12" cy="10" r="3"/>
                                    <path
                                        d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z"/>
                                </svg>
                                <span class="hidden sm:block">Adres:</span>
                            </div>
                            <div class="pt-2">
                                BeWinkel<br/>
                                Vliegend Hertlaan 4D <br/>
                                3526 KT Utrecht<br/>
                            </div>

                            <div class="pt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" class="float-left  mr-2"
                                     width="30"
                                     height="30" viewBox="0 0 24 24" fill="none" stroke="#083d77"
                                     stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                    <circle cx="12" cy="12" r="4"></circle>
                                    <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                </svg>
                                <span class="hidden sm:block">E-mail:</span>
                            </div>
                            <div class="pt-2">
                                <a href="mailto:info@clweb.nl">info@bewinkel.nl</a>
                            </div>
                            <br/>
                            <br/><br/>

                            <small>{{website.name}} is een initiatief van BeWinkel B.V.</small>
                        </div>

                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2452.2290088745162!2d5.104326616109899!3d52.07556087973259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6658b54d12d6d%3A0xecedf2a23c7c0c1d!2sBewinkel!5e0!3m2!1snl!2snl!4v1665146448489!5m2!1snl!2snl"
                            style="border:0;" class="w-full h-64 mb-10" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


                    </div>

                </div>
            </div>
        </div>
    </section>


    <Footer :menu_items="menu_items" :page="page" :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>

</template>


<script>

import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import JetInput from "@/Jetstream/Input.vue";
import JetTextarea from "@/Jetstream/Textarea.vue";
import JetInputError from "@/Jetstream/InputError.vue";
import JetLabel from "@/Jetstream/Label.vue";
import {Head} from "@inertiajs/vue3";

export default {

    components: {
        Header,
        Footer,
        JetInput,
        JetTextarea,
        JetInputError,
        JetLabel,
        JetFormSection,
        JetButton,
        JetActionMessage,
        Head
    },
    props: {
        menu_items: Object,
        page: Object,
        editable: Boolean,
        website: Object,
    },

    data() {
        return {
            form: this.$inertia.form({
                name: this.name,
            }),
        }
    },
    methods: {
        submit() {
            this.form.post(route('contact.email'), {
                errorBag: 'contact',
                preserveScroll: true
            });
        }
    }
}
</script>

